import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DatePicker } from "components";
import { ApiCaller, formatDate, getBgColor } from "utils";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

const Attendence = () => {
  const [date, setDate] = useState(new Date());
  const [attendanceData, setAttendanceData] = useState();
  const selectedStudentData = useSelector((state) => state?.selectedStudent);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const defaultStudent = selectedStudentData?.data;

  const handleDate = (e) => {
    setDate(e);
  };

  useEffect(() => {
    ApiCaller(
      `/student-attendance-records?date=${date}&studentId=${defaultStudent?.studentId}`,
      "get"
    ).then((response) => {
      setAttendanceData(response);
    });
  }, [date, defaultStudent?.studentId]);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={2}>
          <Box>
            <DatePicker onChange={handleDate} value={date} size="large" />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={10}
          container
          spacing={2}
          justifyContent={isMobile ? "center" : "flex-start"}
        >
          {[
            {
              label: "Total Present",
              value: attendanceData?.presentDays,
              backgroundImage: getBgColor(theme.palette.primary.main),
            },
            {
              label: "Total Leave",
              value: attendanceData?.leaveDays,
              backgroundImage: getBgColor("#ff9f00"),
            },
            {
              label: "Total Absent",
              value: attendanceData?.absentDays,
              backgroundImage: getBgColor("#F45159"),
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={3} key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundImage: item.backgroundImage,
                  borderRadius: 3,
                  textAlign: "center",
                  color: "white",
                  minHeight: 60,
                  p: 2,
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  {item.label}:
                </Typography>
                <Typography variant="h6" style={{ marginLeft: 4 }}>
                  {item.value}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table
          sx={{ minWidth: isMobile ? "100%" : 650 }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Attendance</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceData?.attendance?.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{formatDate(row.date)}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Attendence;
