import { useState } from "react";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import account from "_mock/account";
import { useDispatch, useSelector } from "react-redux";
import { setLoginDataAction } from "redux/authentication";
import { setMasterDataAction } from "redux/masterData";
import { setCurrentUserAction } from "redux/currentUser";
import { setUserSettingAction } from "redux/userSetting";
import { useTranslation } from "react-i18next";
import { setSelectedStudentAction } from "redux/selectedStudent";

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const userData = useSelector((state) => state?.currentUser);

  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const { t } = useTranslation();

  const handleNavClick = () => {
    dispatch(setLoginDataAction({ value: false, data: null }));
    dispatch(setMasterDataAction({ value: false, data: null }));
    dispatch(setCurrentUserAction({ value: false, data: null }));
    dispatch(setUserSettingAction({ value: false, data: null }));
    dispatch(setSelectedStudentAction({ value: false, data: null }));
    navigation("/login");
  };

  const MENU_OPTIONS = [
    {
      label: t("home"),
      icon: "eva:home-fill",
      menu: "home",
    },
    {
      label: t("profile"),
      icon: "eva:person-fill",
      menu: "profile",
    },
    {
      label: t("setting"),
      icon: "eva:setting-2-fill",
      menu: "setting",
    },
  ];
  const handleMenuClick = (menu) => {
    if (menu === "home") {
      navigation("/dashboard/app");
    } else if (menu === "profile") {
      navigation("/dashboard/edit-profile");
    } else if (menu === "setting") {
      navigation("/dashboard/setting");
    } else {
    }
    handleClose();
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          src={
            userData?.data?.profileUrl
              ? userData?.data?.profileUrl
              : account.photoURL
          }
          alt="photoURL"
        />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData?.data?.name ?? "User name"}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} overflow>
            {userData?.data?.email ?? ""}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuClick(option.menu)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleNavClick} sx={{ m: 1 }}>
          {t("logout")}
        </MenuItem>
      </Popover>
    </>
  );
}
