import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Paper,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";
import ContactFormContainer from "./ContactFormContainer";

/**
 * Main contact form component with UI elements and snackbar notifications
 */
const ContactForm = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSubmitSuccess = (message) => {
    setSnackbar({
      open: true,
      message,
      severity: "success",
    });
  };

  const handleSubmitError = (message) => {
    setSnackbar({
      open: true,
      message,
      severity: "error",
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  return (
    <Box id="contact" sx={{ py: 8, bgcolor: "#fbfcf8" }}>
      <Container maxWidth="lg">
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} md={10} lg={8}>
            <Box textAlign="center" mb={6}>
              <Typography
                variant="h3"
                component="h2"
                gutterBottom
                fontWeight="bold"
              >
                Get in Touch
              </Typography>
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{ maxWidth: 600, mx: "auto" }}
              >
                Have questions about GurukulGuru? Fill out the form below and
                our team will get back to you shortly.
              </Typography>
            </Box>

            <Paper
              elevation={3}
              sx={{
                p: { xs: 3, md: 5 },
                borderRadius: 4,
                background:
                  "linear-gradient(to right bottom, #ffffff, #f9f9ff)",
              }}
            >
              <ContactFormContainer
                onSubmitSuccess={handleSubmitSuccess}
                onSubmitError={handleSubmitError}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactForm;
