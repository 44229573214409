import { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Grid,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableContainer,
  Card,
  IconButton,
  Paper,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";

import {
  ListHead,
  ListToolbar,
  LoaderWrapper,
  TablePaginationActions,
} from "components";
import { ApiCaller, downloadReceipt, validateData } from "utils";
import { fDate } from "utils/formatTime";
import { useDelay } from "hooks";
import { useTranslation } from "react-i18next";
import { FileDownloadOutlined } from "@mui/icons-material";
import useResponsive from "hooks/useResponsive";

const StudentNotice = () => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [setDeletedIds] = useState([]);
  const [setIsModalOpen] = useState(false);
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [setSelectedData] = useState({});
  const { t } = useTranslation();
  const mdUp = useResponsive("up", "md");
  //   const { handleUploadFile, uploadedFileUrl } = useUploadFile();

  const TABLE_HEAD = [
    { id: "title", label: t("title"), alignRight: false },
    { id: "description", label: t("description"), alignRight: false },
    { id: "date", label: t("dateOfAnouncement"), alignRight: false },
    { id: "eventDuration", label: t("eventDuration"), alignRight: false },
    { id: "eventDuration", label: t("attachment"), alignRight: false },
  ];

  const noticeList = notices?.data || [];

  const isNotFound = !noticeList.length;

  const handleSearchChange = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchNoticeData = () => {
    setLoading(true);
    ApiCaller(
      `/notice-board?search=${searchedValue}&pageNo=${
        page + 1
      }&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setNotices(response);
        setLoading(false);
      }
    });
  };

  const searchedValue = useDelay(searchValue, 1000);
  useEffect(fetchNoticeData, [deletedToken, page, rowsPerPage, searchedValue]);
  const getFileName = (file) => {
    const spilletedArray = file?.split("/");
    const fileName = spilletedArray[spilletedArray?.length - 1];
    return fileName;
  };

  return (
    <>
      <Container>
        <Box mt={2}>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              value={searchValue}
              onChange={handleSearchChange}
              handleDelete={() => {
                setIsModalOpen(true);
              }}
              isBulkDelete={isBulkDelete}
              placeholder={t("Search notice...")}
            >
              {/*
              Other filter would be here
            */}
            </ListToolbar>
            <TableContainer sx={{ overflow: "auto" }}>
              <Table>
                <ListHead
                  headLabel={TABLE_HEAD}
                  rowCount={noticeList.length}
                  numSelected={selected.length}
                  isCheckBoxEnable={false}
                />
                <TableBody>
                  {noticeList.map((row) => {
                    const {
                      detail,
                      createdAt,
                      _id,
                      title,
                      eventStartDate,
                      eventEndDate,
                    } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    const eventDuration = `${fDate(eventStartDate)} - ${fDate(
                      eventEndDate
                    )}`;
                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        selected={selectedUser}
                      >
                        <TableCell align="left">
                          {validateData(title)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(detail)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(fDate(createdAt))}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(eventDuration)}
                        </TableCell>
                        <TableCell align="center">
                          {row?.attachment ? (
                            <IconButton
                              onClick={() =>
                                downloadReceipt(
                                  row?.attachment,
                                  getFileName(row?.attachment)
                                )
                              }
                            >
                              <FileDownloadOutlined color="primary" />
                            </IconButton>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No result found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <LoaderWrapper loading={loading} />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={notices.total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Card>
        </Box>
      </Container>
    </>
  );
};
export default StudentNotice;
