import React, { useState } from "react";
import { Grid, Button, CircularProgress } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import MessageIcon from "@mui/icons-material/Message";
import PersonIcon from "@mui/icons-material/Person";
import ContactFormField from "./ContactFormField";
import {
  ApiCaller,
  validateEmail,
  validateError,
  validatePhone,
} from "../../utils";

/**
 * Container component that handles form state and validation
 */
const ContactFormContainer = ({ onSubmitSuccess, onSubmitError }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateForm = () => {
    const newErrors = {
      name: "",
      email: "",
      phone: "",
      description: "",
    };
    let isValid = true;

    if (!formData.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email";
      isValid = false;
    }

    if (!formData.phone) {
      newErrors.phone = "Phone number is required";
      isValid = false;
    } else if (!validatePhone(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
      isValid = false;
    }

    if (!formData.description) {
      newErrors.description = "Description is required";
      isValid = false;
    } else if (formData.description.length < 10) {
      newErrors.description = "Description should be at least 10 characters";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear error when user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      // In a real application, you would send this data to your backend
      // For now, we'll simulate a successful submission
      console.log("Form submitted:", formData);

      try {
        ApiCaller("/send-mail", "post", formData).then((response) => {
          if (response?.success) {
            onSubmitSuccess(
              "Thank you for contacting us! We will get back to you soon."
            );
          } else {
            onSubmitError(validateError(response?.data));
          }
        });
      } catch (error) {
        console.log("error", error);
      }

      // Show success message via callback

      // Reset form
      setFormData({
        name: "",
        email: "",
        phone: "",
        description: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      onSubmitError("Failed to submit form. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ContactFormField
            icon={<PersonIcon />}
            label="Your Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={errors.name}
            helperText={errors.name}
          />
        </Grid>

        <Grid item xs={12}>
          <ContactFormField
            icon={<EmailIcon />}
            label="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            helperText={errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <ContactFormField
            icon={<PhoneIcon />}
            label="Phone Number"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            helperText={errors.phone}
          />
        </Grid>

        <Grid item xs={12}>
          <ContactFormField
            icon={<MessageIcon />}
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            error={errors.description}
            helperText={errors.description}
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={isSubmitting}
            fullWidth
            sx={{
              py: 1.5,
              borderRadius: 2,
              fontWeight: 600,
              textTransform: "none",
            }}
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Submit"
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactFormContainer;
