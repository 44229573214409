import { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Grid,
  TextField,
  Container,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  TableContainer,
  Card,
  Checkbox,
  IconButton,
  Paper,
  Button,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import {
  DatePicker,
  Dropdown,
  ErrorMsg,
  Iconify,
  ListHead,
  ListToolbar,
  LoaderWrapper,
  TablePaginationActions,
} from "components";
import {
  ApiCaller,
  capitalizeFirstLetter,
  downloadReceipt,
  handleCheckBox,
  validateData,
  validateError,
} from "utils";
import ConfirmationBox from "components/ConfirmationBox";
import { fDate } from "utils/formatTime";
import { useDelay, useToaster, useUploadFile } from "hooks";
import { addSchoolKitSchema } from "./testSchema";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { FileDownloadOutlined, CloudUpload } from "@mui/icons-material";
import { AWS_FOLDERS, DEFAULT_USER_PROFILE } from "utils/constants";
import useResponsive from "hooks/useResponsive";

const NoticeBoard = () => {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deletedToken, setDeletedToken] = useState("");
  const [isBulkDelete, setIsBulkDelete] = useState(false);
  const [deletedIds, setDeletedIds] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notices, setNotices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { t } = useTranslation();
  const [noticeImage, setNoticeImage] = useState("");
  const mdUp = useResponsive("up", "md");
  const { handleUploadFile, uploadedFileUrl } = useUploadFile();

  const TABLE_HEAD = [
    { id: "title", label: t("title"), alignRight: false },
    { id: "description", label: t("description"), alignRight: false },
    { id: "noticeType", label: t("noticeType"), alignRight: false },
    { id: "date", label: t("dateOfAnouncement"), alignRight: false },
    { id: "eventDuration", label: t("eventDuration"), alignRight: false },
    { id: "eventDuration", label: t("attachment"), alignRight: false },
    { id: "", label: t("action"), alignRight: false },
  ];

  const triggerToaster = useToaster();

  const theme = useTheme();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(addSchoolKitSchema),
  });

  const noticeList = notices?.data || [];

  const handleDelete = () => {
    const selectedKit = noticeList.filter(
      (kit) => selected.includes(kit?._id) && kit._id
    );
    const ids = selectedKit.map((kit) => kit._id);
    onDeleteNotice(ids);
  };

  const onDeleteNotice = (ids) => {
    setDeletedIds(ids);
    setIsBulkDelete(true);
    ApiCaller(`/notice-board`, "delete", { ids }).then((response) => {
      if (response.success) {
        handleDeleteResponse(
          t("Notice is deleted successfully."),
          t("success")
        );
      } else {
        handleDeleteResponse(t("somethingWentWrong"), t("error"));
      }
    });
  };

  const handleDeleteResponse = (msg, msgType) => {
    setTimeout(() => {
      setIsBulkDelete(false);
      setDeletedToken(new Date().getTime().toString());
      setSelected([]);
      setIsModalOpen(false);
      triggerToaster(msg, msgType);
    }, 2000);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = noticeList.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const isNotFound = !noticeList.length;

  const handleSearchChange = (event) => {
    setPage(0);
    setSearchValue(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchNoticeData = () => {
    setLoading(true);
    ApiCaller(
      `/notice-board?search=${searchedValue}&pageNo=${
        page + 1
      }&size=${rowsPerPage}`,
      "get"
    ).then((response) => {
      if (response.success) {
        setNotices(response);
        setLoading(false);
      }
    });
  };

  const searchedValue = useDelay(searchValue, 1000);
  useEffect(fetchNoticeData, [deletedToken, page, rowsPerPage, searchedValue]);

  const noticeId = selectedData?._id;

  const handleAddUpdateExpense = () => {
    setLoading(true);

    const body = {
      ...getValues(),
      attachment: noticeImage,
    };

    if (noticeId) {
      // Update notice kit API call
      ApiCaller(`/notice-board/${noticeId}`, "put", body).then((response) => {
        if (response?.success) {
          triggerToaster(t("Notice is updated successfully"), t("success"));
          handleAddUpdateRes();
        } else {
          triggerToaster(
            validateError(response.data) || "Invalid data",
            "error"
          );
        }
        setSelectedData({});
        setNoticeImage(DEFAULT_USER_PROFILE);
        setLoading(false);
      });
    } else {
      // Add notice API call
      ApiCaller("/notice-board", "post", {
        ...body,
        availableQuantity: getValues("quantity"),
      }).then((response) => {
        if (!!response?.success) {
          triggerToaster(t("Notice is added successfully"), t("success"));
          handleAddUpdateRes();
        } else {
          triggerToaster(
            response?.message || validateError(response?.data),
            "error"
          );
        }
        setNoticeImage(DEFAULT_USER_PROFILE);
        setLoading(false);
      });
    }
  };

  const handleAddUpdateRes = () => {
    reset();
    fetchNoticeData();
  };

  const isLoading = (id) => deletedIds.includes(id) && isBulkDelete;
  const handleProfileImageChange = (e, folder) => {
    const file = e.target.files[0];
    if (file) {
      handleUploadFile(file, folder);
    }
  };

  useEffect(() => {
    setNoticeImage(uploadedFileUrl);
  }, [uploadedFileUrl]);

  const getFileName = (file) => {
    const spilletedArray = file?.split("/");
    const fileName = spilletedArray[spilletedArray?.length - 1];
    return fileName;
  };

  return (
    <>
      <Container>
        <Typography variant="h5">{t("noticeBoard")}</Typography>
        <Box
          as="form"
          onSubmit={handleSubmit(handleAddUpdateExpense)}
          style={{
            backgroundColor: theme.palette.common.white,
            padding: 18,
            borderRadius: 10,
            border: `1px solid ${theme.palette.grey[300]}`,
            display: "flex",
            flexDirection: mdUp ? "row" : "column-reverse",
          }}
        >
          <Box style={{ width: mdUp ? "80%" : "100%" }}>
            <Grid
              container
              gap={2}
              marginTop={3}
              direction={{ xs: "column", md: "row" }}
            >
              <Grid item xs={4}>
                <Controller
                  control={control}
                  name="title"
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      label={`${t("title")} *`}
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                <ErrorMsg msg={errors?.title?.message} />
              </Grid>
              <Grid item xs={mdUp ? 4 : 12}>
                <Controller
                  control={control}
                  name="detail"
                  defaultValue={""}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      size="small"
                      sx={{ width: "100%" }}
                      label={`${t("description")}`}
                      onChange={onChange}
                      value={value}
                      multiline={true}
                    />
                  )}
                />
              </Grid>
              <Grid item md={mdUp ? 3 : 12}>
                <Controller
                  control={control}
                  name="noticeRecipientType"
                  defaultValue={t("all")}
                  render={({ field }) => (
                    <Dropdown
                      itemList={[t("all"), t("student"), t("staff")]}
                      label={`${t("noticeRecipientType")} *`}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  )}
                />
              </Grid>

              <Grid
                container
                gap={2}
                marginTop={3}
                direction={{ xs: "column", md: "row" }}
              >
                <Grid item xs={mdUp ? 4 : 12}>
                  <Controller
                    control={control}
                    name="eventStartDate"
                    defaultValue={new Date()}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label={t("eventStartDate")}
                        value={value}
                        onChange={onChange}
                        disablePast
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={mdUp ? 4 : 12}>
                  <Controller
                    control={control}
                    name="eventEndDate"
                    defaultValue={new Date()}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        label={t("eventEndDate")}
                        value={value}
                        onChange={onChange}
                        disablePast
                        renderInput={(params) => <TextField {...params} />}
                      />
                    )}
                  />
                  <ErrorMsg msg={errors?.eventEndDate?.message} />
                </Grid>
                <Grid item xs={mdUp ? 3 : 12}>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    style={{ alignContent: "right", width: "100%" }}
                    loading={loading}
                  >
                    {noticeId ? t("updateNotice") : t("addNotice")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box
            textAlign="center"
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: mdUp ? "20%" : "100%",
              borderRadius: 10,
              // padding: 20,
              border: `1px solid ${theme.palette.grey[200]}`,
            }}
          >
            <Box
              component="img"
              src={noticeImage ? noticeImage : DEFAULT_USER_PROFILE}
              sx={{
                width: mdUp ? "120px" : "150px",
                height: mdUp ? "120px" : "150px",
                borderRadius: 2,
                objectFit: "cover",
              }}
            />
            <Button
              component="label"
              role={undefined}
              variant="outlined"
              style={{ marginTop: 10 }}
              startIcon={<CloudUpload />}
            >
              {t("uploadFile")}
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) =>
                  handleProfileImageChange(e, AWS_FOLDERS.NOTICE)
                }
              />
            </Button>
          </Box>
        </Box>
        <Box mt={2}>
          <Card>
            <ListToolbar
              numSelected={selected.length}
              value={searchValue}
              onChange={handleSearchChange}
              handleDelete={() => {
                setIsModalOpen(true);
              }}
              isBulkDelete={isBulkDelete}
              placeholder={t("Search notice...")}
            >
              {/*
              Other filter would be here
            */}
            </ListToolbar>

            <TableContainer sx={{ overflow: "auto" }}>
              <Table>
                <ListHead
                  headLabel={TABLE_HEAD}
                  rowCount={noticeList.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {noticeList.map((row) => {
                    const {
                      detail,
                      createdAt,
                      noticeRecipientType,
                      _id,
                      title,
                      eventStartDate,
                      eventEndDate,
                    } = row;
                    const selectedUser = selected.indexOf(_id) !== -1;
                    const eventDuration = `${fDate(eventStartDate)} - ${fDate(
                      eventEndDate
                    )}`;

                    return (
                      <TableRow
                        hover
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={selectedUser}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUser}
                            onChange={(event) =>
                              handleCheckBox(event, _id, selected, setSelected)
                            }
                          />
                        </TableCell>
                        <TableCell align="left">
                          {validateData(title)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(detail)}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(
                            capitalizeFirstLetter(noticeRecipientType)
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(fDate(createdAt))}
                        </TableCell>
                        <TableCell align="left">
                          {validateData(eventDuration)}
                        </TableCell>
                        <TableCell align="center">
                          {row?.attachment ? (
                            <IconButton
                              onClick={() =>
                                downloadReceipt(
                                  row?.attachment,
                                  getFileName(row?.attachment)
                                )
                              }
                            >
                              <FileDownloadOutlined color="primary" />
                            </IconButton>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ width: 70 }}>
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={() => {
                                setSelectedData(row);
                                setValue("title", row?.title);
                                setValue("detail", row?.detail);
                                setValue(
                                  "noticeRecipientType",
                                  row.noticeRecipientType
                                );
                                setValue(
                                  "eventStartDate",
                                  new Date(row?.eventStartDate)
                                );
                                setValue(
                                  "eventEndDate",
                                  new Date(row?.eventEndDate)
                                );
                                setNoticeImage(row?.attachment);
                              }}
                              sx={{ padding: 0.6 }}
                            >
                              <Iconify icon={"eva:edit-fill"} />
                            </IconButton>
                            {!isLoading(row?._id) ? (
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setSelected([row?._id]);
                                }}
                                sx={{ padding: 0.6 }}
                              >
                                <Iconify
                                  icon={"eva:trash-2-outline"}
                                  sx={{ color: theme.palette.error.dark }}
                                />
                              </IconButton>
                            ) : (
                              <IconButton size="large" color="inherit">
                                <Iconify icon="eos-icons:bubble-loading" />
                              </IconButton>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={9} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: "center",
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            No result found
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                <LoaderWrapper loading={loading} />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={rowsPerPage}
              component="div"
              count={notices.total ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Card>
          <ConfirmationBox
            handleClose={() => setIsModalOpen(false)}
            handleConfirm={() => {
              handleDelete();
            }}
            loading={isBulkDelete}
            open={isModalOpen}
          />
        </Box>
      </Container>
    </>
  );
};
export default NoticeBoard;
