import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const FeatureCard = ({ icon: Icon, title, description }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 3,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        bgcolor: "#fff",
        border: "1px solid",
        borderColor: "divider",
        transition: "all 0.3s ease",
        "&:hover": {
          bgcolor: "#e8eef9",
          opacity: 0.9,
          transform: "translateY(-5px)",
          boxShadow: 3,
        },
      }}
    >
      <Box
        sx={{
          width: 48,
          height: 48,
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2.5,
          bgcolor: "#e8eef9",
          color: "primary.main",
        }}
      >
        <Icon sx={{ fontSize: 24 }} />
      </Box>
      <Typography variant="h6" component="h3" sx={{ mb: 1, fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ flexGrow: 1 }}>
        {description}
      </Typography>
    </Paper>
  );
};

export default FeatureCard;
