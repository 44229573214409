export const THEME_COLOR = "#06011a";
export const YELLOW_COLOR = "#f2bb27";
export const WHITE_COLOR = "#ffffff";
export const SECONDARY_BLUE_COLOR = "#3b8898";
export const DEFAULT_USER_PROFILE = "/assets/images/avatars/avatar_default.jpg";
export const ERP = "/assets/images/erp.png";

export const AWS_FOLDERS = {
  STUDENTS: "students",
  STAFFS: "staffs",
  INSTITUTES: "institutes",
  INVOICES: "feeInvoices",
  SALARY: "salaryInvoices",
  SALES: "sales",
  NOTICE: "notice",
};

export const STATES = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];
