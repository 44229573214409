import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const TestimonialCard = ({ content, author, role, school, image }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 4,
        bgcolor: "background.paper",
        border: "1px solid",
        borderColor: "divider",
        transition: "all 0.3s ease",
        "&:hover": {
          boxShadow: 4,
        },
      }}
    >
      <FormatQuoteIcon
        sx={{
          fontSize: 48,
          color: "primary.main",
          opacity: 0.2,
          mb: 2,
        }}
      />

      <Typography
        variant="body1"
        color="text.primary"
        sx={{
          mb: 4,
          flexGrow: 1,
          fontStyle: "italic",
        }}
      >
        {content}
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={image}
          alt={author}
          sx={{ width: 50, height: 50, mr: 2 }}
        />
        <Box>
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {author}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {role}, {school}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default TestimonialCard;
