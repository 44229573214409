import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FeatureCard from "./FeatureCard";
import {
  PersonAdd,
  AssignmentTurnedIn,
  AttachMoney,
  Notifications,
  MenuBook,
  People,
  CalendarMonth,
  BarChart,
  CreditCard,
} from "@mui/icons-material";

const Features = () => {
  const features = [
    {
      icon: PersonAdd,
      title: "Admission Management",
      description:
        "Simplify student enrollment with digital forms, document uploads, and automated application processing.",
    },
    {
      icon: AssignmentTurnedIn,
      title: "Attendance Tracking",
      description:
        "Record and monitor student and staff attendance with real-time reporting and notification systems.",
    },
    {
      icon: AttachMoney,
      title: "Fee Management",
      description:
        "Streamline fee collection, generate invoices, and send automated payment reminders to parents.",
    },
    {
      icon: CreditCard,
      title: "Salary Processing",
      description:
        "Manage staff payroll, calculate deductions, and generate detailed salary reports automatically.",
    },
    {
      icon: Notifications,
      title: "Noticeboard",
      description:
        "Share announcements, events, and important information with students, parents, and staff.",
    },
    {
      icon: MenuBook,
      title: "Result Management",
      description:
        "Record, calculate, and publish student grades and performance reports with comprehensive analytics.",
    },
    {
      icon: BarChart,
      title: "Sales & Expenses",
      description:
        "Track financial transactions, manage budgets, and generate detailed financial reports.",
    },
    {
      icon: People,
      title: "Student Portal",
      description:
        "Give students access to assignments, grades, attendance, and communication with teachers.",
    },
    {
      icon: CalendarMonth,
      title: "Academic Calendar",
      description:
        "Plan and manage school events, holidays, exams, and class schedules in an interactive calendar.",
    },
  ];

  return (
    <Box
      id="features"
      sx={{
        py: { xs: 8, md: 12 },
        background: (theme) =>
          `linear-gradient(180deg, #FFFFFF 0%, ${"#e8eef9"}20 100%)`,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: "center",
            mb: { xs: 6, md: 8 },
            mx: "auto",
            maxWidth: "md",
          }}
        >
          <Box
            sx={{
              display: "inline-block",
              bgcolor: "#e8eef9",
              color: "primary.main",
              px: 2,
              py: 0.75,
              mb: 2,
              borderRadius: "100px",
              typography: "caption",
              fontWeight: "medium",
            }}
          >
            Comprehensive AI-Powered Solution
          </Box>
          <Typography
            variant="h3"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            Everything you need to manage your school efficiently
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Our platform integrates powerful features to streamline
            administrative tasks and enhance the educational experience.
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {features.map((feature, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <FeatureCard
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
