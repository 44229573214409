import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const footerLinks = [
    {
      title: "Product",
      links: [
        { label: "Features", href: "#features" },
        { label: "Pricing", href: "#" },
        { label: "Case Studies", href: "#" },
        { label: "Testimonials", href: "#testimonials" },
      ],
    },
    {
      title: "Company",
      links: [
        { label: "About Us", href: "#" },
        { label: "Careers", href: "#" },
        { label: "Blog", href: "#" },
        { label: "Contact", href: "#" },
      ],
    },
    {
      title: "Legal",
      links: [
        { label: "Privacy Policy", href: "#" },
        { label: "Terms of Service", href: "#" },
        { label: "Cookie Policy", href: "#" },
        { label: "GDPR", href: "#" },
      ],
    },
  ];

  return (
    <Box sx={{ bgcolor: "primary.main", py: 2 }}>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            // justifyContent: "space-between",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="#fff">
            © {currentYear} GurukulGuru. All rights reserved.
          </Typography>

          {/* <Box sx={{ display: "flex", mt: { xs: 2, sm: 0 } }}>
            <IconButton
              color="primary"
              aria-label="Facebook"
              component="a"
              href="#"
            >
              <FacebookIcon />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="Twitter"
              component="a"
              href="#"
            >
              <TwitterIcon />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="Instagram"
              component="a"
              href="#"
            >
              <InstagramIcon />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="LinkedIn"
              component="a"
              href="#"
            >
              <LinkedInIcon />
            </IconButton>
          </Box> */}
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
