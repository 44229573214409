import fetch from "cross-fetch";

const LOCAL = "http://localhost:80";
const QA_URL = "https://sm-backend-ybq3.onrender.com";
export const URL =
  window.location?.origin === "http://localhost:3000" ? LOCAL : QA_URL;

const ApiCaller = (endpoint, method = "get", body, isChatbot = false) => {
  const store = JSON.parse(localStorage.getItem("persist:root"));
  const loginData = JSON.parse(store?.loginData);

  const token = loginData?.data?.token;

  let requestOptions = {};
  if (!isChatbot) {
    requestOptions = {
      method: method,
      headers: { "Content-Type": "application/json", "x-access-token": token },
      body: JSON.stringify(body),
    };
  } else {
    requestOptions = {
      method: method,
      headers: { "x-access-token": token },
      body,
    };
  }

  return fetch(`${URL}${endpoint}`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      return {
        status: 100,
        message: "Ooops, Something went wrong, Check your internet connection",
      };
    });
};

export default ApiCaller;
