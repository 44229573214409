import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { motion } from "framer-motion";
import TestimonialCard from "./TestimonialCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const defaultUser =
  "https://github.com/user-attachments/assets/80824408-b8cd-4875-a65c-ebca6f787c44";

const Testimonials = () => {
  const testimonials = [
    {
      content:
        "GurukulGuru has transformed how we manage our school. The administrative burden has been reduced significantly, allowing our staff to focus more on teaching and less on paperwork.",
      author: "Rahul Singh",
      role: "Principal",
      school: "S.S Convent School",
      image: "",
    },
    {
      content:
        "The integrated fee management system has streamlined our financial processes. Parents appreciate the transparency and convenience of online payments, and our accounting team loves the automated reconciliation.",
      author: "Hardyansh Shukla",
      role: "Director",
      school: "Vision School",
      image: "",
    },
    {
      content:
        "GurukulGuru has made attendance tracking seamless for us. It's easy to use, and teachers find it extremely efficient.",
      author: "Anjali Verma",
      role: "Teacher",
      school: "Modern Public School",
      image: "",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const visibleCards = isMobile ? 1 : 2;

  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex + visibleCards) % testimonials.length
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? testimonials.length - visibleCards
        : prevIndex - visibleCards
    );
  };

  return (
    <Box
      id="testimonials"
      sx={{
        py: { xs: 8, md: 12 },
        background: "#FFFFFF",
        maxWidth: "100%",
        textAlign: "center",
      }}
    >
      <Container maxWidth="lg">
        <Box sx={{ mb: { xs: 6, md: 8 }, mx: "auto", maxWidth: "md" }}>
          <Box
            sx={{
              display: "inline-block",
              bgcolor: "#ffffff",
              color: "primary.main",
              px: 2,
              py: 0.75,
              mb: 2,
              borderRadius: "100px",
              typography: "caption",
              fontWeight: "medium",
            }}
          >
            Trusted by Schools
          </Box>
          <Typography
            variant="h3"
            component="h2"
            sx={{ mb: 2, fontWeight: 700 }}
          >
            What school administrators say
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Discover how GurukulGuru has helped schools streamline their
            operations and enhance their educational experience.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
          }}
        >
          <IconButton
            onClick={handlePrev}
            sx={{ transition: "0.3s", "&:hover": { transform: "scale(1.2)" } }}
          >
            <ArrowBackIcon />
          </IconButton>
          <motion.div
            key={currentIndex}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            style={{ display: "flex", gap: "16px" }}
          >
            {[...Array(visibleCards)].map((_, offset) => {
              const index = (currentIndex + offset) % testimonials.length;
              return (
                <TestimonialCard
                  key={index}
                  content={testimonials[index].content}
                  author={testimonials[index].author}
                  role={testimonials[index].role}
                  school={testimonials[index].school}
                  image={testimonials[index].image || defaultUser}
                />
              );
            })}
          </motion.div>
          <IconButton
            onClick={handleNext}
            sx={{ transition: "0.3s", "&:hover": { transform: "scale(1.2)" } }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Testimonials;
