import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Router from "./routes";
import ThemeProvider from "./theme";
import { StyledChart } from "components/Chart";
import ScrollToTop from "components/ScrollToTop";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { useSelector } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";

// import { appStore, persistedStore } from "./redux";
import "./index.css";
import { CustomToaster } from "components";
import LoadingScreen from "pages/LoadingScreen";
import "./utils/i18n"; // Make sure this is correctly importing i18n
import { useCurrentUser } from "hooks";
import Index from "./website/pages/Index";

const queryClient = new QueryClient();

export default function App() {
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.loginData?.data);
  const triggerCurrentuser = useCurrentUser();

  useEffect(() => {
    // Simulate content loading with a timeout
    setTimeout(() => {
      setLoading(false);
      triggerCurrentuser();
    }, 1500); // Adjust the timeout as needed
  }, [userData]);

  return loading ? (
    // <Provider store={appStore}>
    <LoadingScreen />
  ) : (
    // </Provider>
    // <Provider store={appStore}>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        {/* <PersistGate loading={null} persistor={persistedStore}> */}
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
            {/* <Index /> */}
            <CustomToaster />
          </ThemeProvider>
        </BrowserRouter>
        {/* </PersistGate> */}
      </QueryClientProvider>
    </HelmetProvider>
    // </Provider>
  );
}
