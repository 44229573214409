import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import autoMergeLevel1 from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import saga from "./rootSaga";

import reducer from "./reducer";

const persistConfig = {
  key: "root",
  storage: autoMergeLevel1,
  whitelist: [
    "loginData",
    "masterData",
    "currentUser",
    "userSetting",
    "selectedStudent",
  ],
};

const storageReducer = persistReducer(persistConfig, reducer);
const sagaMiddlewareObj = createSagaMiddleware();
const middlewareArr = [sagaMiddlewareObj, logger];

export const appStore = configureStore({
  reducer: storageReducer,
  devTools: process.env.NODE_ENV !== "devlopment",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewareArr),
});

sagaMiddlewareObj.run(saga);

export const persistedStore = persistStore(appStore);
