import {
  Typography,
  Box,
  Grid,
  Container,
  Divider,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import {
  ApiCaller,
  formatDate,
  getMonthYear,
  getSlicedArray,
  moneyFormat,
} from "utils";
import { BarChart } from "@mui/x-charts/BarChart";
import { ViewOnly } from "components";
import {
  getFormattedAdditionalPaidBreakUp,
  getFormattedPaidBreakUp,
} from "pages/AddFees/makeData";
import { DEFAULT_USER_PROFILE } from "utils/constants";
import useResponsive from "hooks/useResponsive";
import { useSelector } from "react-redux";
const StudentHome = () => {
  const [selectedStudent, setSelectedStudent] = useState({});
  const [feesDetails, setFeesDetails] = useState({});
  const selectedStudentData = useSelector((state) => state?.selectedStudent);
  const theme = useTheme();
  const mdUp = useResponsive("up", "md");

  const defaultStudent = selectedStudentData?.data;
  console.log("defaultStudent", defaultStudent);
  useEffect(() => {
    ApiCaller(
      `/students?key=_id&value=${defaultStudent?.studentId}`,
      "get"
    ).then((response) => {
      if (response.success) {
        const studentData = response?.data?.[0];

        setSelectedStudent(studentData);
        ApiCaller(
          `/get-student-fees-details/${defaultStudent?.studentId}?batchId=${defaultStudent?.batchId}`,
          "get"
        ).then((response) => {
          if (response.success) {
            setFeesDetails(response?.data);
          }
        });
      }
    });
  }, [defaultStudent]);

  const formattedRemainingData = feesDetails?.fees?.map((item) => {
    return item.remainingAmount;
  });

  const formattedPaidData = feesDetails?.fees?.map((item) => {
    return item.paidAmount;
  });

  const formattedDueData = feesDetails?.fees?.map((item) => {
    const calculatedDue = item?.totalDueBreakup?.reduce(
      (total, item) => total + item?.value,
      0
    );
    return calculatedDue;
  });

  const months = feesDetails?.fees?.map((item) => {
    const fromMonth =
      getMonthYear(item?.toDate) !== getMonthYear(item?.fromDate)
        ? getMonthYear(item?.toDate)
        : "";

    return `${getMonthYear(item?.fromDate)} ${
      fromMonth ? "- " : ""
    }${fromMonth}`;
  });

  return (
    <>
      <Helmet>
        <title> Student details | GurukulGuru.com </title>
      </Helmet>

      <Container style={{ marginLeft: 0, marginRight: 0 }}>
        <Box>
          <Typography variant="h3" style={{ marginBottom: 12 }}>
            Student details
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: mdUp ? "row" : "column-reverse",
            }}
          >
            <Box
              style={{
                width: mdUp ? "75%" : "100%",
                padding: 10,
                backgroundColor: theme.palette.common.white,
                borderRadius: 15,
                marginRight: 10,
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <Typography variant="h5" style={{ marginLeft: 12 }}>
                Student Graph
              </Typography>
              <BarChart
                width={mdUp ? window.innerWidth / 1.8 : window.innerWidth}
                height={300}
                series={[
                  {
                    data: getSlicedArray(formattedDueData ?? []),
                    label: "Due Fee",
                    id: "DueFeeId",
                  },
                  {
                    data: getSlicedArray(formattedPaidData ?? []),
                    label: "Paid Fee",
                    id: "paidFeeId",
                  },
                  {
                    data: getSlicedArray(formattedRemainingData ?? []),
                    label: "RemainIng Fee",
                    id: "remainingFeeId",
                  },
                ]}
                xAxis={[
                  { data: getSlicedArray(months ?? []), scaleType: "band" },
                ]}
                colors={["#1890FF", "#8cbcac", "#ff575c"]}
              />
              <Box>
                <Grid
                  container
                  direction={{ xs: "column", md: "row" }}
                  spacing={2}
                  mt={0}
                  ml={mdUp ? 0 : 2}
                  sx={{ width: "100%" }}
                >
                  <Grid item xs={3}>
                    <ViewOnly
                      label={"Course fees"}
                      value={moneyFormat(feesDetails?.totalCourseFees)}
                      breakUpData={feesDetails?.totalAmountBreakUp}
                      isPopUpView={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={"Received fees history"}
                      value={moneyFormat(feesDetails?.totalPaidAmounts)}
                      isModalView={true}
                      breakUpData={getFormattedPaidBreakUp(feesDetails?.fees)}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={"Received additional fees history"}
                      value={moneyFormat(feesDetails?.totalAdditionFees)}
                      breakUpData={getFormattedAdditionalPaidBreakUp(
                        feesDetails?.addtionalFeesBreakUp
                      )}
                      headers={["Reason", "Amount"]}
                      columnsKeys={["reason", "amount"]}
                      isModalView={true}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <ViewOnly
                      label={"Discounts history"}
                      value={moneyFormat(feesDetails?.totalDiscounts)}
                      breakUpData={getFormattedPaidBreakUp(feesDetails?.fees)}
                      isModalView={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box
              style={{
                width: mdUp ? "25%" : "100%",
                backgroundColor: theme.palette.common.white,
                padding: 10,
                borderRadius: 15,
                marginBottom: mdUp ? 0 : 12,
                border: `1px solid ${theme.palette.grey[300]}`,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedStudent?.profileUrl ? (
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: 50,
                      marginTop: 10,
                    }}
                    alt="Student Profile"
                    src={selectedStudent?.profileUrl}
                  ></img>
                ) : (
                  <img
                    style={{
                      height: 100,
                      width: 100,
                      borderRadius: 50,
                      marginTop: 10,
                    }}
                    alt="Student Profile"
                    src={DEFAULT_USER_PROFILE}
                  />
                )}
              </Box>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 10,
                  fontWeight: "bold",
                }}
              >
                {selectedStudent?.name}
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {selectedStudent?.rollNo}
              </Typography>
              <Divider
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                }}
              />
              <Box style={{ paddingLeft: 10 }}>
                <Typography style={{ fontSize: 14 }}>
                  <b>Father: </b>
                  {selectedStudent?.fatherName}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Mother: </b>
                  {selectedStudent?.motherName}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Roll No: </b>
                  {selectedStudent?.rollNo}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Gender: </b>
                  {selectedStudent?.gender}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Caste: </b>
                  {selectedStudent?.caste}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>DOB: </b>
                  {formatDate(selectedStudent?.dob)}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Adhar No: </b>
                  {selectedStudent?.adharNo}
                </Typography>
              </Box>
              <Divider
                style={{
                  marginTop: 10,
                }}
              />
              <Box style={{ padding: 10 }}>
                <Typography style={{ fontSize: 14 }}>
                  <b>Mobile No: </b>
                  {selectedStudent?.mobile}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Admission Date: </b>
                  {formatDate(selectedStudent?.admissionDate)}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Batch: </b>
                  {selectedStudent?.batch}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Pin Code: </b>
                  {selectedStudent?.pinCode}
                </Typography>
                <Typography style={{ fontSize: 14 }}>
                  <b>Address: </b>
                  {selectedStudent?.address}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};
export default StudentHome;
