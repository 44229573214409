import { createSlice } from "@reduxjs/toolkit";

export const selectedStudent = createSlice({
  name: "selectedStudent",
  initialState: {
    value: false,
    data: {},
  },
  reducers: {
    setSelectedStudentAction: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSelectedStudentAction } = selectedStudent.actions;

export default selectedStudent.reducer;
