import PropTypes from "prop-types";
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@mui/material";
import { AutoCompleteSearch, Iconify, Loader } from "components";
import useResponsive from "hooks/useResponsive";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

ListToolbar.propTypes = {
  numSelected: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  handleDelete: PropTypes.func,
  isBulkDelete: PropTypes.bool,
};

export default function ListToolbar({
  numSelected,
  onChange,
  value,
  handleDelete,
  isBulkDelete,
  children,
  placeholder = "Search user...",
  isSearchBar = true,
  handleSelectedData = () => {},
  searchType = "student",
}) {
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "#e8eef9",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            width: "100%",
          }}
        >
          {isSearchBar ? (
            <StyledSearch
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          ) : (
            <AutoCompleteSearch
              label={
                searchType === "student"
                  ? t("searchStudentByNameRollNo")
                  : t("searchStaffByNameStaffId")
              }
              passSelectedValue={handleSelectedData}
              sx={{ width: mdUp ? "100%" : "190%" }}
              searchType={searchType}
            />
          )}
          {children}
        </Box>
      )}

      {numSelected > 0 && !isBulkDelete && (
        <Tooltip title="Delete">
          <IconButton onClick={handleDelete}>
            <Iconify icon="eva:trash-2-fill" />
          </IconButton>
        </Tooltip>
      )}
      <Loader loading={isBulkDelete} />
    </StyledRoot>
  );
}
