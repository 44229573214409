import useMediaQuery from "@mui/material/useMediaQuery";
import ApiCaller from "./axiosApiCaller";
import { alpha } from "@mui/material";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function IsMobile() {
  const matches = useMediaQuery("(max-width:600px)");
  return matches;
}

const getSubString = (value, length) => {
  if (value?.length > length) {
    return `${value?.substring(0, length)}...${value?.substring(
      value?.length - 4,
      value?.length
    )}`;
  }
  return value;
};

const validateData = (value) => {
  if (value) {
    return value;
  }
  return "-";
};

const formatDate = (value) => {
  if (value) {
    const date = new Date(value);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }
  return "-";
};

const monthYear = (value) => {
  const date = new Date(value);
  const month = String(date.getMonth());
  const year = date.getFullYear();
  return `${monthNames[month]}-${year}`;
};

const validateError = (value) => {
  if (typeof value === "string") {
    return value;
  }
  return "Something went wrong";
};
const handleCheckBox = (event, name, selected, setSelected) => {
  const selectedIndex = selected.indexOf(name);
  let newSelected = [];
  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, name);
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
  }
  setSelected(newSelected);
};

const getCurrentMonthFirstAndLastDay = () => {
  // Get the current date
  const currentDate = new Date();

  // Set the date to the first day of the current month
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  // Set the date to the last day of the current month
  const lastDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );

  // Now you can format the dates as needed
  // Example formatting for output
  const formattedFirstDay = firstDayOfMonth.toLocaleDateString();
  const formattedLastDay = lastDayOfMonth.toLocaleDateString();
  return {
    firstDayOfMonth: formattedFirstDay,
    lastDayOfMonth: formattedLastDay,
  };
};

// Format money amounts
const moneyFormat = (amount) => {
  // Check if the input is a valid number
  if (isNaN(amount)) {
    return "-";
  }

  // Create a new Intl.NumberFormat object for formatting currency
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  // Format the amount using the formatter object
  return formatter.format(amount);
};

// Get months and fees calculation
function getMonthsBetweenDatesAndFees(startDate, endDate, monthFees) {
  let currentDate = new Date(startDate);
  const endDateObj = new Date(endDate);
  const months = [];

  while (currentDate <= endDateObj) {
    const year = currentDate.getFullYear();
    const month = monthNames[currentDate.getMonth()];

    // months.push({ key: `${month},${year}`, value: monthFees });
    months.push({ key: `${month}-${year}`, value: monthFees });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
}

// Get months from date
function getMonthYear(startDate) {
  let currentDate = new Date(startDate);
  const year = currentDate.getFullYear();
  const month = monthNames[currentDate.getMonth()];

  const formattedMonth = `${month}${year}`;

  return formattedMonth;
}

// Splice array
const getSlicedArray = (arr) => {
  return arr.slice(0, 12)?.reverse();
};

const downloadReceipt = async (receipt, name) => {
  const response = await fetch(receipt);
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name); // Set the desired file name
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const getBgColor = (color, dark = 0.9, colorDarkness = 0.1) =>
  `linear-gradient(315deg, ${alpha(color, colorDarkness)} 0%, ${alpha(
    color,
    dark
  )} 100%)`;

const getImageBlob = async (url) => {
  try {
    const response = await fetch(url);

    // Check if the response is ok
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    // Convert response to a blob
    const blob = await response.blob();

    // Create a URL from the blob
    const imageBlobUrl = URL.createObjectURL(blob);
    console.log("imageBlobUrl", imageBlobUrl);
    return imageBlobUrl;
  } catch (error) {
    console.error("Error fetching the image:", error);
  }
};

const getCsvDataIntoArray = (csvContent) => {
  // Split the content into rows
  const rows = csvContent.trim().split("\n");

  // Extract the header row
  const headers = rows[0].split(",");

  // Create an array of objects by mapping each row to the header keys
  const data = rows
    .slice(1)
    .map((row) => {
      const values = row.split(",");
      const obj = headers.reduce((acc, header, index) => {
        acc[header.trim()] = values[index]?.trim() || null;
        return acc;
      }, {});

      // Check if all values are null or empty and filter them out
      const isEmptyRow = Object.values(obj).every((value) => !value);
      return isEmptyRow ? null : obj;
    })
    .filter(Boolean); // Filter out null objects

  return data;
};

const downloadCsv = (name = "student_bulk_upload_sample_file") => {
  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = `${process.env.PUBLIC_URL}/${name}.csv`; // Path to your CSV file in the public folder
  link.download = `${name}.csv`; // Filename to download
  link.click(); // Programmatically click the anchor to trigger download
};

const capitalizeFirstLetter = (sentence) => {
  if (!sentence) return ""; // Return empty string if input is empty
  return sentence
    .split(" ") // Split the sentence into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join the words back into a sentence
};

const changeDateFormat = (date) => {
  if (date) {
    if (date.includes("-")) {
      const d = date.split("-");
      return `${d[1]}/${d[0]}/${d[2]}`; //
    } else {
      return date;
    }
  }
};

/**
 * Validates an email address format
 * @param {string} email - The email to validate
 * @returns {boolean} - Whether the email is valid
 */
const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

/**
 * Validates a phone number (10 digits)
 * @param {string} phone - The phone number to validate
 * @returns {boolean} - Whether the phone number is valid
 */
const validatePhone = (phone) => {
  const regex = /^[0-9]{10}$/;
  return regex.test(phone);
};

export {
  ApiCaller,
  IsMobile,
  getSubString,
  validateData,
  validateError,
  handleCheckBox,
  formatDate,
  monthYear,
  getCurrentMonthFirstAndLastDay,
  getMonthsBetweenDatesAndFees,
  moneyFormat,
  getMonthYear,
  getSlicedArray,
  downloadReceipt,
  getBgColor,
  getImageBlob,
  getCsvDataIntoArray,
  downloadCsv,
  capitalizeFirstLetter,
  changeDateFormat,
  validateEmail,
  validatePhone,
};
