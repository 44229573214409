import { Box, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  ApiCaller,
  downloadReceipt,
  formatDate,
  moneyFormat,
  monthYear,
  validateData,
} from "utils";
import { Receipt } from "@mui/icons-material";
import { useSelector } from "react-redux";

const StudentFees = () => {
  const [feesDetails, setFeesDetails] = useState([]);
  const selectedStudentData = useSelector((state) => state?.selectedStudent);

  const defaultStudent = selectedStudentData?.data;

  useEffect(() => {
    ApiCaller(`/get-student-fee/${defaultStudent?.studentId}`, "get").then(
      (response) => {
        setFeesDetails(response?.data);
      }
    );
  }, [defaultStudent?.studentId]);

  const theme = useTheme();

  if (!feesDetails?.length) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "60vh", // Adjust based on the layout
          width: "100%",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No data found
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      {feesDetails.map((fees, index) => (
        <Box key={index}>
          <Typography fontWeight="bold" sx={{ margin: 1 }}>
            {monthYear(fees?.createdAt)}
          </Typography>

          <Box
            sx={{
              width: "100%",
              p: 2,
              border: `1px solid ${theme.palette.grey[200]}`,
              borderRadius: "10px",
              backgroundColor: "#fff",
              mb: 2,
            }}
          >
            {/* Student Details */}
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 5, md: 3 }}
            >
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Student Name</Typography>
                <Typography>{validateData(fees?.studentId?.name)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Father's Name</Typography>
                <Typography>{fees?.studentId?.fatherName}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">DOB</Typography>
                <Typography>{formatDate(fees?.studentId?.dob)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Roll No.</Typography>
                <Typography>{fees?.studentId?.rollNo}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Batch</Typography>
                <Typography>{fees?.batchId?.name}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Payment Date</Typography>
                <Typography>{formatDate(fees?.createdAt)}</Typography>
              </Grid>
            </Grid>

            {/* Fees Details */}
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 5, md: 3 }}
              mt={1}
            >
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">From Date</Typography>
                <Typography>{formatDate(fees.fromDate)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">To Date</Typography>
                <Typography>{formatDate(fees.toDate)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Paid</Typography>
                <Typography>{moneyFormat(fees?.paidAmount)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Discount</Typography>
                <Typography>{moneyFormat(fees?.discountAmount)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Remaining</Typography>
                <Typography>{moneyFormat(fees?.remainingAmount)}</Typography>
              </Grid>
              <Grid xs={6} sm={4} md={2}>
                <Typography fontWeight="bold">Receipt</Typography>
                <Typography>
                  {fees?.receipt ? (
                    <IconButton
                      onClick={() =>
                        downloadReceipt(fees?.receipt, "fee_receipt.pdf")
                      }
                    >
                      <Receipt color="primary" />
                    </IconButton>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default StudentFees;
