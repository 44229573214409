import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";

import { useCurrentUser, useToaster } from "hooks";
import { Iconify, ErrorMsg } from "components";
import { ApiCaller } from "utils";
import { loginSchema } from "./testSchema";
import { setLoginDataAction } from "redux/authentication";
import { setSelectedStudentAction } from "redux/selectedStudent";

export default function LoginForm() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triggerToaster = useToaster();
  const triggerCurrentuser = useCurrentUser();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(loginSchema),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleNavigation = (loginData) => {
    if (loginData?.role === "admin") {
      if (loginData?.instituteId) {
        navigate("/dashboard/app");
      } else {
        navigate("/dashboard/addInstitute");
      }
    } else if (loginData?.role === "student") {
      if (!!loginData?.linkedStudents?.length) {
        dispatch(
          setSelectedStudentAction({
            value: true,
            data: loginData?.linkedStudents?.[0],
          })
        );
        setTimeout(() => {
          navigate("/dashboard/student-home");
        }, 300);
      } else {
        navigate("/dashboard/link-student");
      }
    } else {
      navigate("/dashboard/linkInstitute");
    }
  };

  const handleLogin = () => {
    setLoading(true);
    ApiCaller("/login", "post", getValues()).then((response) => {
      if (response.success) {
        const loginData = { ...response.data, token: response.token };
        dispatch(setLoginDataAction({ value: true, data: loginData }));
        triggerToaster("Login successful", "success");
        triggerCurrentuser();
        handleNavigation(loginData);
      } else {
        triggerToaster(response.data || "Invalid data", "error");
      }
      setLoading(false);
    });
  };

  const handleSignUp = () => {
    navigate("/register");
  };

  return (
    <Box as="form" onSubmit={handleSubmit(handleLogin)}>
      <Stack spacing={3} mt={2}>
        <Box>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, value } }) => (
              <TextField
                sx={{ width: "100%" }}
                label="Email *"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <ErrorMsg msg={errors?.email?.message} />
        </Box>
        <Box>
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Password *"
                sx={{ width: "100%" }}
                type={showPassword ? "text" : "password"}
                onChange={onChange}
                value={value}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <ErrorMsg msg={errors?.password?.message} />
        </Box>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        <Stack direction="row" alignItems="center">
          {/* <Controller
            control={control}
            name="isRemember"
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                name="remember"
                label="Remember me"
                onChange={onChange}
                value={value}
              />
            )}
          />
          <Typography variant="body2">Remember me</Typography> */}
        </Stack>
        <Button
          variant="text"
          sx={{ textDecoration: "underline" }}
          onClick={() => navigate("/forgotPassword")}
        >
          Forgot password?
        </Button>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!isDirty}
        loading={loading}
      >
        Login
      </LoadingButton>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Don’t have an account?
        <Button
          variant="text"
          sx={{ textDecoration: "underline" }}
          onClick={handleSignUp}
        >
          Get started
        </Button>
      </Typography>
    </Box>
  );
}
