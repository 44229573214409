import { useSelector } from "react-redux";

export const useDashboardUrl = () => {
  const userData = useSelector((state) => state?.currentUser?.data);
  if (userData?.role === "admin") {
    return "/dashboard/app";
  } else if (userData?.role === "student") {
    return "/dashboard/student-home";
  } else {
    return "/dashboard/staff-home";
  }
};
