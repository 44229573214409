import { useState } from "react";
import { Stack, TextField, Box, Typography, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useToaster } from "hooks";
import { ErrorMsg, DatePicker } from "components";
import { setLoginDataAction } from "redux/authentication";
import { ApiCaller } from "utils";
import useResponsive from "hooks/useResponsive";

export default function LinkInstitute() {
  const [loading, setLoading] = useState(false);
  const [instituteData, setInstituteData] = useState({});
  const [isDirtyFile, setIsDirtyFile] = useState(false);
  const userData = useSelector((state) => state?.currentUser);
  const mdUp = useResponsive("up", "md");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const triggerToaster = useToaster();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: "onTouched",
  });

  const handleLinkStudent = () => {
    setLoading(true);
    const body = {
      mobile: getValues("mobile").trim(),
      rollNo: getValues("rollNo").trim(),
      userId: userData?.data?._id,
    };

    ApiCaller("/link-student", "post", body).then((response) => {
      if (response?.success) {
        triggerToaster(response?.message, "success");
      } else {
        triggerToaster(response?.message, "error");
      }
      setLoading(false);
      navigate("/dashboard/student-home");
    });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleLinkStudent)}
      p={4}
      style={{ backgroundColor: "#fff" }}
    >
      <Typography
        variant="h4"
        mb={0}
        style={{ display: "flex", justifyContent: "center" }}
      >
        Link Student
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={0}
        alignItems="center"
      >
        <Grid container spacing={2} width={"50%"}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="rollNo"
              defaultValue={instituteData?.rollNo || ""}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  label="Roll No *"
                  size="small"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <ErrorMsg msg={errors?.rollNo?.message} />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="mobile"
              defaultValue={instituteData?.mobile || ""}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  label="Registered Mobile*"
                  size="small"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            <ErrorMsg msg={errors?.mobile?.message} />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={!isDirty && !isDirtyFile}
              loading={loading}
            >
              Link Student
            </LoadingButton>
          </Grid>
        </Grid>
        <Box mt={2} style={{ display: "flex", marginLeft: 100 }}>
          <img
            src="/assets/images/study.png"
            alt="institute"
            width={mdUp ? "70%" : "60%"} // मोबाइल में इमेज छोटी होगी
            style={{ borderRadius: 8 }}
          />
        </Box>
      </Stack>
    </Box>
  );
}
