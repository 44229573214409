import React from "react";
import { Box, TextField } from "@mui/material";

/**
 * Reusable form field component for the contact form
 */
const ContactFormField = ({
  icon,
  label,
  name,
  value,
  onChange,
  error,
  helperText,
  multiline = false,
  rows = 1,
}) => {
  return (
    <Box display="flex" alignItems="flex-start">
      {icon &&
        React.cloneElement(icon, { color: "primary", sx: { mt: 2, mr: 1 } })}
      <TextField
        fullWidth
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        error={!!error}
        helperText={helperText}
        variant="outlined"
        required
        multiline={multiline}
        rows={rows}
        InputProps={{
          sx: { borderRadius: 2 },
        }}
      />
    </Box>
  );
};

export default ContactFormField;
