import { useTranslation } from "react-i18next";
import React from "react";
import { Box } from "@mui/material";
import Student from "pages/Student";

const Icon = ({ name }) => {
  return (
    <Box p={0.5} bgcolor={"#fff"} borderRadius={4}>
      <img
        src={`/assets/icons/${name}.png`}
        alt={name}
        height={20}
        width={20}
      />
    </Box>
  );
};

const useConfig = (userData) => {
  const { t } = useTranslation();

  const studentMenu = [
    {
      title: t("home"),
      path: "/dashboard/student-home",
      icon: <Icon name="home" />,
    },
    {
      title: t("student"),
      icon: <Icon name="student" />,
      children: [
        {
          title: t("Attendance"),
          path: "/dashboard/attendence",
          icon: <Icon name="studentAttendence" />,
        },
        // {
        //   title:t("Result"),
        //   path:"/dashboard/result",
        //   icon: <Icon name="" />,
        // },
        {
          title: t("Fee"),
          path: "/dashboard/student-fees",
          icon: <Icon name="fee" />,
        },
        {
          title: t("Notice"),
          path: "/dashboard/student-notice",
          icon: <Icon name="noticeBoard" />,
        },
      ],
    },
    {
      title: t("AI tools"),
      icon: <Icon name="ai" />,
      children: [
        {
          title: t("Chat Bot"),
          path: "/dashboard/chat-bot",
          icon: <Icon name="chatbot" />,
        },
        {
          title: t("Text Extraction"),
          path: "/dashboard/text-extraction",
          icon: <Icon name="text-extraction" />,
        },
      ],
    },

    {
      title: t("backup"),
      path: "/dashboard/backup",
      icon: <Icon name="backup" />,
    },
    {
      title: t("logout"),
      path: "/login",
      icon: <Icon name="logout" />,
    },
  ];

  const adminMenu = [
    {
      title: t("dashboard"),
      path: "/dashboard/app",
      icon: <Icon name="home" />,
    },
    {
      title: t("administration"),
      icon: <Icon name="admin" />,
      children: [
        {
          title: t("institute"),
          path: "/dashboard/addInstitute",
          icon: <Icon name="institute" />,
        },
        {
          title: t("staff"),
          path: "/dashboard/staff",
          icon: <Icon name="staff" />,
        },
        {
          title: t("batch"),
          path: "/dashboard/batch",
          icon: <Icon name="batch" />,
        },
        {
          title: t("student"),
          path: "/dashboard/student",
          icon: <Icon name="student" />,
        },
        {
          title: t("fees"),
          path: "/dashboard/fees",
          icon: <Icon name="fee" />,
        },
        {
          title: t("salary"),
          path: "/dashboard/salary",
          icon: <Icon name="salary" />,
        },
        {
          title: t("studentAttendance"),
          path: "/dashboard/student-attendence",
          icon: <Icon name="studentAttendence" />,
        },
        {
          title: t("staffAttendance"),
          path: "/dashboard/staff-attendence",
          icon: <Icon name="staffAttendence" />,
        },
      ],
    },
    {
      title: t("instituteStore"),
      icon: <Icon name="instituteStore" />,
      children: [
        {
          title: t("expenses"),
          path: "/dashboard/expenses",
          icon: <Icon name="expense1" />,
        },
        {
          title: t("instituteKit"),
          path: "/dashboard/school-kit",
          icon: <Icon name="school_kit" />,
        },
        {
          title: t("sellInstituteKit"),
          path: "/dashboard/sell-school-kit",
          icon: <Icon name="sale" />,
        },
      ],
    },
    {
      title: t("academicUpdate"),
      icon: <Icon name="academicUpdate" />,
      children: [
        {
          title: t("noticeBoard"),
          path: "/dashboard/notice-board",
          icon: <Icon name="noticeBoard" />,
        },
      ],
    },
    {
      title: t("bulkUpload"),
      path: "/dashboard/bulk-upload",
      icon: <Icon name="upload" />,
    },
    {
      title: t("AI tools"),
      icon: <Icon name="ai" />,
      children: [
        {
          title: t("Chat Bot"),
          path: "/dashboard/chat-bot",
          icon: <Icon name="chatbot" />,
        },
        {
          title: t("Text Extraction"),
          path: "/dashboard/text-extraction",
          icon: <Icon name="text-extraction" />,
        },
      ],
    },

    {
      title: t("backup"),
      path: "/dashboard/backup",
      icon: <Icon name="backup" />,
    },
    {
      title: t("logout"),
      path: "/login",
      icon: <Icon name="logout" />,
    },
  ];

  if (userData?.role === "admin") {
    return adminMenu;
  } else if (userData?.role === "student") {
    return studentMenu;
  } else {
    return adminMenu;
  }
};

export default useConfig;
