import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { Upload } from "components";
import {
  ApiCaller,
  capitalizeFirstLetter,
  changeDateFormat,
  downloadCsv,
  getCsvDataIntoArray,
  validateError,
} from "utils";
import { useToaster } from "hooks";
import useResponsive from "hooks/useResponsive";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const StudentBulkUpload = () => {
  const triggerToaster = useToaster();
  const [loading, setLoading] = useState(false);
  const mdUp = useResponsive("up", "md");
  const { t } = useTranslation();

  const handleFileUpload = (fileContent) => {
    setLoading(true);
    const csvDataIntoArray = getCsvDataIntoArray(fileContent);
    let formattedData = [];

    if (csvDataIntoArray?.length) {
      let hasError = false;

      formattedData = csvDataIntoArray?.map((item, index) => {
        const joiningDate = changeDateFormat(item?.["JOINING DATE"]);

        const data = {
          name: item?.["NAME"],
          mobile: item?.["MOBILE NO"],
          email: item?.["EMAIL"],
          gender: capitalizeFirstLetter(item?.["GENDER"]),
          joiningDate,
          role: capitalizeFirstLetter(item?.["ROLE"]),
          salaryType: capitalizeFirstLetter(item?.["SALARY TYPE"]),
          salaryAmount: item?.["SALARY AMOUNT"],
        };

        for (const [key, value] of Object.entries(data)) {
          const formattedValue = capitalizeFirstLetter(value);
          if (!value) {
            triggerToaster(
              `Field ${key} is empty in row ${index + 1}`,
              "error"
            );
            hasError = true;
            setLoading(false);
          } else if (
            key === "salaryType" &&
            !(
              formattedValue === "Course Wise" ||
              formattedValue === "Monthly" ||
              formattedValue === "One Time"
            )
          ) {
            triggerToaster(
              "Salary type should be one of: Course Wise, Monthly, or One Time.",
              "error"
            );
            hasError = true;
            setLoading(false);
          } else if (
            key === "role" &&
            !(
              formattedValue === "Admin" ||
              formattedValue === "Teacher" ||
              formattedValue === "Principal" ||
              formattedValue === "Other"
            )
          ) {
            triggerToaster(
              "Role should be one of: Admin, Teacher, Principal or Other.",
              "error"
            );
            hasError = true;
            setLoading(false);
          }
        }

        return data;
      });

      if (hasError) return;

      ApiCaller("/staffs-bulk-upload", "post", { staffs: formattedData }).then(
        (response) => {
          if (response?.success) {
            triggerToaster("Staffs are added successfully", "success");
          } else {
            triggerToaster(validateError(response?.data), "error");
          }
          setLoading(false);
        }
      );
    }
  };

  return (
    <Box sx={{ backgroundColor: "#ffffff" }}>
      {loading && <LinearProgress style={{ marginBottom: 10 }} />}
      <Upload
        padding={3}
        onFileUpload={handleFileUpload}
        acceptedFileType="csv"
      >
        <Box
          style={{
            display: "flex",
            flexDirection: mdUp ? "row" : "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Header names should be the same as in the provided sample CSV file.
          </Typography>
          <Button onClick={() => downloadCsv("staff_bulk_upload_sample_file")}>
           {t("clickToDownloadTheStaffBulkUploadSampleFile")}
          </Button>
        </Box>
      </Upload>
    </Box>
  );
};

export default StudentBulkUpload;
