import React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { ERP } from "utils/constants";

const Hero = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        pt: 12,
        display: "flex",
        alignItems: "center",
        position: "relative",
        backgroundColor: "#ffffff",
        overflow: "hidden",
        // background: `linear-gradient(180deg, ${"#e8eef9"}20 0%, transparent 100%)`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          right: 0,
          width: "50%",
          height: 0,
          paddingBottom: "50%",
          borderRadius: "50%",
          background: `${"#e8eef9"}30`,
          filter: "blur(100px)",
          zIndex: -1,
        }}
      />

      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid item xs={12} md={6} sx={{ zIndex: 1 }}>
            <Box
              sx={{
                display: "inline-block",
                bgcolor: "#e8eef9",
                color: "primary.main",
                px: 2,
                py: 0.75,
                mb: 3,
                borderRadius: "100px",
                typography: "caption",
                fontWeight: "medium",
              }}
            >
              Streamline Your School Management with AI-Powered Efficiency
            </Box>

            <Typography
              variant="h2"
              component="h1"
              sx={{
                fontWeight: "bold",
                mb: 3,
                fontSize: { xs: "2.5rem", md: "3.5rem" },
                lineHeight: 1.2,
              }}
            >
              AI-Powered School Management Software
            </Typography>

            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ mb: 4, maxWidth: 520 }}
            >
              A Comprehensive AI-Powered Solution to Simplify Administration,
              Enhance Communication, and Improve Educational Outcomes.
            </Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
                mb: 3,
              }}
            >
              <Button
                href="https://gurukulguru.com/register"
                variant="contained"
                color="primary"
                size="large"
                sx={{ px: 3, py: 1.5 }}
              >
                Start Your Free Trial
              </Button>
              <Button
                href={`https://wa.me/${process.env.REACT_APP_SUPPORT_MOBILE_NO}`}
                variant="outlined"
                color="primary"
                target="_blank"
                size="large"
                sx={{ px: 3, py: 1.5 }}
              >
                Book a Demo
              </Button>
            </Box>

            <Typography variant="body2" color="text.secondary">
              No credit card required • Available Free
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
              }}
            >
              <Box
                // elevation={4}
                sx={{
                  p: 1.5,
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <Box
                  component="img"
                  src={ERP}
                  alt="School Management Dashboard"
                  sx={{
                    width: "100%",
                    height: "auto",
                    display: "block",
                    // borderRadius: 3,
                    aspectRatio: "4/3.8",
                    objectFit: "cover",
                  }}
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  left: -20,
                  bottom: -20,
                  width: 100,
                  height: 100,
                  borderRadius: "50%",
                  bgcolor: "#e8eef9",
                  opacity: 0.2,
                  filter: "blur(40px)",
                  zIndex: -1,
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  right: -30,
                  top: -30,
                  width: 160,
                  height: 160,
                  borderRadius: "50%",
                  bgcolor: "primary.main",
                  opacity: 0.1,
                  filter: "blur(50px)",
                  zIndex: -1,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
