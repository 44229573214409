import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  useTheme,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import { AddCircleOutline as AddIcon, ChangeCircle } from "@mui/icons-material";
import { setSelectedStudentAction } from "redux/selectedStudent";

import account from "_mock/account";
import useResponsive from "hooks/useResponsive";
import Logo from "components/Logo";
import Scrollbar from "components/Scrollbar";
import NavSection from "components/NavSection";

import useConfig from "./useConfig";
import { useDispatch, useSelector } from "react-redux";
import { getBgColor } from "utils";

const NAV_WIDTH = 250;

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // backgroundColor: alpha(theme.palette.grey[500], 0.12),
  backgroundImage: getBgColor(theme.palette.primary.main, 0.8, 0.1),
}));

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const userData = useSelector((state) => state?.currentUser);
  const theme = useTheme();
  const navConfig = useConfig(userData?.data);
  const selectedStudentData = useSelector((state) => state?.selectedStudent);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const defaultStudent = selectedStudentData?.data;

  useEffect(() => {
    setSelectedStudent(defaultStudent);
  }, [defaultStudent]);

  // Student Select Handler
  const handleSelectStudent = (student) => {
    if (student) {
      setSelectedStudent(student);
      handleClose();
      console.log("student", student);
      dispatch(setSelectedStudentAction({ value: true, data: student }));
      navigate("/dashboard/student-home");
    }
  };

  const isDesktop = useResponsive("up", "lg");
  const linkedStudents = userData?.data?.linkedStudents || [];

  const getUserView = () => {
    if (userData?.data?.role === "student") {
      return (
        <Box sx={{ mb: 1, mx: 1, position: "relative" }}>
          <Link underline="none">
            <StyledAccount>
              <Box display="flex" alignItems="center">
                <Avatar
                  src={selectedStudent?.profileUrl || account.photoURL}
                  alt="photoURL"
                />
                <Box
                  sx={{ ml: 2, overflowWrap: "break-word", inlineSize: 155 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "text.white", fontWeight: "bold" }}
                  >
                    {selectedStudent?.name ?? "User name"}
                  </Typography>
                </Box>
              </Box>
            </StyledAccount>
          </Link>

          {/* Change Student Button */}
          <IconButton
            onClick={handleOpen}
            sx={{
              position: "absolute",
              bottom: 10,
              right: 4,
              color: "white",
              backgroundColor: "primary.main",
              "&:hover": { backgroundColor: "primary.dark" },
            }}
          >
            <ChangeCircle />
          </IconButton>

          {/* Expandable Dropdown Menu */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {linkedStudents.length > 0 ? (
              linkedStudents.map((student) => (
                <MenuItem
                  key={student.studentId}
                  onClick={() => handleSelectStudent(student)}
                >
                  <Avatar
                    src={student.profileUrl || ""}
                    alt={student.name}
                    sx={{ mr: 1 }}
                  />
                  {student.name}
                  {` (${student.rollNo})`}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No linked students</MenuItem>
            )}
            <Button
              variant="outlined"
              fullWidth
              sx={{ mt: 2 }}
              onClick={() => {
                handleClose();
                navigate("/dashboard/link-student");
              }}
            >
              Link New Student
            </Button>
          </Menu>
        </Box>
      );
    } else {
      return (
        <Box sx={{ mb: 1, mx: 1 }}>
          <Link underline="none">
            <StyledAccount>
              <Avatar
                src={
                  userData?.data?.profileUrl
                    ? userData?.data?.profileUrl
                    : account.photoURL
                }
                alt="photoURL"
              />
              <Box sx={{ ml: 2, overflowWrap: "break-word", inlineSize: 155 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: "text.white", fontWeight: "bold" }}
                >
                  {userData?.data?.name ?? "User name"}
                </Typography>
              </Box>
            </StyledAccount>
          </Link>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 2.5, display: "inline-flex" }}>
        <Logo />
      </Box>

      {getUserView()}
      <NavSection data={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              // bgcolor: "background.default",
              borderRightStyle: "dashed",
              backgroundColor: theme.palette.common.white,
              // boxShadow: 1px 2px 9px ${theme.palette.grey[500]},
              // borderRadius: 3,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
